<template>
  <div>
    <!-- MOLDING学院 -->
    <div class="mdHeader">
      <h1>助力注塑专业人员走向成功</h1>
      <ul>
        <li><a href="#">为员工投资</a></li>
        <li><a href="#">降低成本与浪费</a></li>
        <li><a href="#">改善产品输出与品质</a></li>
      </ul>
    </div>
    <div class="mdMiddle">
      <div class="mdMiddleContent">
        <h3>职业发展</h3>
      </div>
      <div class="mdMiddleContent">
        <h3>接班人计划</h3>
      </div>
      <div class="mdMiddleContent">
        <h3>员工士气</h3>
      </div>
      <div class="mdMiddleContent">
        <h3>减少人才流失</h3>
      </div>
    </div>
    <!-- <div class="mdAdvantage">
      <h1>我们的优势</h1>
    </div> -->
  </div>
</template>

<script>
export default {};
</script>

<style scope>
* {
  margin: 0;
  padding: 0;
}
.mdHeader {
  width: 1600px;
  margin: 120px auto;
}
.mdHeader ul {
  margin-top: 80px;
  display: flex;
  justify-content: center;
}
.mdHeader ul li {
  width: 170px;
  height: 40px;
  margin-right: 60px;
  border-bottom: 1px solid #ccc;
}
.mdHeader ul li a {
  font-size: 16px;
  color: #000;
}
.mdMiddle {
  width: 1300px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
}
.mdMiddle .mdMiddleContent {
  width: 270px;
  height: 360px;
  background: rgb(184, 216, 229);
  margin-bottom: 100px;
}
.mdMiddle .mdMiddleContent h3 {
  margin-top: 20px;
  margin-left: 30px;
}
</style>